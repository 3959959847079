.career {
  background: #fff;
  flex: 1;

  figure {
    color: #fff;
  }

  .inner {
    padding: 0;
    margin: 0;
  }

  .career_content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #ffffff;
    padding: 0 40px;
  }

  .content_wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    padding: 0;
    justify-content: space-between;
    font-family: NotoSansKR, sans-serif;
    box-sizing: border-box;
  }

  .contents {
    width: 100%;
    max-width: 960px;
    padding: 100px 0;
    margin-left: 0px;
  }

  .content_title {
    width: auto;
    flex: 1;
    font-family: NotoSansKR, sans-serif;
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #010101;
  }

  .content_title_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .content_title_date {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    color: #969da9;
  }

  .content_item {
    margin-top: 40px;
  }

  .item_title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #010101;
    margin-bottom: 4px;
  }

  .item_description {
    font-family: NotoSansKR, sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    color: #010101;
  }

  // 우리가 일하는 환경
  .environment {
    margin-top: 140px;
  }

  .environment_items {
    display: flex;
    flex-wrap: wrap;
  }

  .environment_item {
    width: 280px;
    height: 173px;
    display: inline-block;
    margin: 40px 37px 0 0;
    padding: 41px 30px 40px;
    border: solid 1px #d4d4d4;
  }

  .environment_title {
    font-family: NotoSansKR, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .environment_description {
    font-family: NotoSansKR, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-top: 20px;
  }

  // 채용중인 직군
  .position {
    margin-top: 140px;
  }

  // 채용 프로세스
  .process {
    margin-top: 140px;
  }

  // 온리안 입사 지원
  .career_apply {
    width: 100%;
    height: 100%;
    background: #000000;
    padding: 40px;
  }

  .apply {
    width: 100%;
    max-width: 960px;
    padding: 0;
    margin-right: 110px;
  }

  .apply .content_title {
    color: #ffffff;
  }

  .apply_item {
    width: 49%;
    display: inline-block;
    margin-top: 40px;
    padding-right: 40px;
  }

  .apply_item.full {
    width: 100%;
  }

  .apply_title {
    font-family: NotoSansKR, sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .apply_require {
    font-family: NotoSansKR, sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #22cace;
    margin-left: 4px;
  }

  .apply_input {
    width: 100%;
    height: 54px;
    font-family: NotoSansKR, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969da9;
    outline: none;
    border: none;
    background: transparent;
    border-bottom: 3px solid #ffffff;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  .apply_btn {
    padding: 16px 101.5px 15px 102.5px;
    border-radius: 30px;
    background-color: #ffffff;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    opacity: 0.3;
    cursor: pointer;
  }

  .apply_file_wrapper {
    display: flex;
    justify-content: flex-start;
    height: 48px;
    align-items: center;
    margin-top: 20px;
    border: 3px solid #ffffff;
    padding-left: 21px;
  }

  .apply_input_label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    overflow: hidden;
    cursor: pointer;
    font-family: NotoSansKR, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 48px;
    letter-spacing: normal;
    color: #969da9;
    position: relative;
    z-index: 1000;
  }

  .d-xs-none {
    display: none;
  }

  .apply_input_file {
    display: none;
  }

  .apply_input_btn {
    position: absolute;
    right: 20px;
    z-index: 900;
  }

  .text-right {
    text-align: right !important;
  }

  /* Checkbox 영역 */

  .checkbox{
    display: none;
  }
  .checkbox_label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .checkbox {
    + {
      .checkbox_icon {
        background: url("../static/img/none-bk/none-bk.png") no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        padding: 0;
      }
    }
  }

  .checkbox {
    &:checked {
      + {
        .checkbox_icon {
          background: url('../static/img/checked/checked.png') no-repeat;
          width: 16px;
          height: 16px;
          display: inline-block;
          padding: 0;
        }
      }
    }
  }


  .checkbox_name {
    font-family: NotoSansKR, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-left: 4px;
  }
}


//Career 모바일 반응형
@media screen and (max-width: $mp) {

  .career {
    background: #fff;
    flex: 1;

    figure {
      color: #fff;
      .box{
        p{
          margin-left: 0px;
        }
      }
    }

    .inner {
      padding: 0;
      margin: 0;
    }

    .career_content {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: #ffffff;
      padding: 0;
    }

    .content_wrapper {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      display: block;
      padding: 100px 40px;
      justify-content: space-between;
      font-family: Montserrat, sans-serif;
      box-sizing: border-box;
    }

    .contents {
      width: 100%;
      max-width: 960px;
      padding: 0;
      margin-left: 0px;
    }

    .content_title {
      width: auto;
      flex: 1;
      font-family: NotoSansKR, sans-serif;
      font-size: 24px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #010101;
    }

    .content_title_wrapper {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .content_title_date {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      color: #969da9;
    }

    .content_item {
      margin-top: 30px;
    }

    .item_title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #010101;
      margin-bottom: 10px;
    }

    .item_description {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      color: #010101;
    }

    // 우리가 일하는 환경
    .environment {
      margin-top: 100px;
    }

    .environment_items {
      display: flex;
      flex-wrap: wrap;
    }

    .environment_item {
      width: 100%;
      height: 167px;
      display: inline-block;
      margin-right: 0px;
      padding: 41px 30px 40px;
      border: solid 1px #d4d4d4;
      font-size: 18px;
    }

    .environment_title {
      font-family: NotoSansKR, sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .environment_description {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-top: 20px;
    }

    // 채용중인 직군
    .position {
      margin-top: 84px;
      div:nth-child(2) {
        margin-top: 40px;
      }
    }

    // 채용 프로세스
    .process {
      margin-top: 100px;
    }

    // 온리안 입사 지원
    .career_apply {
      width: 100%;
      height: 100%;
      background: #000000;
      padding: 60px 0px;
    }

    .apply {
      width: 100%;
      max-width: 960px;
      padding: 0;
      margin-right: 0px;
    }

    .apply .content_title {
      color: #ffffff;
    }

    .apply_item {
      width: 100%;
      display: inline-block;
      margin-top: 40px;
      padding-right: 40px;
    }

    .apply_item.full {
      width: 100%;
    }

    .apply_title {
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }

    .apply_require {
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #22cace;
      margin-left: 4px;
    }

    .apply_input {
      width: 100%;
      height: 54px;
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #969da9;
      outline: none;
      border: none;
      background: transparent;
      border-bottom: 3px solid #ffffff;
      -webkit-appearance: none;
      -webkit-border-radius: 0;
    }

    .apply_btn {
      padding: 16px 101.5px 15px 102.5px;
      border-radius: 30px;
      background-color: #ffffff;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      opacity: 0.3;
      justify-content: center;
      cursor: pointer;
    }

    .apply_file_wrapper {
      display: flex;
      justify-content: flex-start;
      height: 48px;
      align-items: center;
      margin-top: 20px;
      border: 3px solid #ffffff;
      padding-left: 21px;
    }

    .apply_input_label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background: transparent;
      overflow: hidden;
      cursor: pointer;
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 48px;
      letter-spacing: normal;
      color: #969da9;
      position: relative;
      z-index: 1000;
    }

    .d-xs-none {
      display: none;
    }

    .apply_input_file {
      display: none;
    }

    .apply_input_btn {
      position: absolute;
      right: 20px;
      z-index: 900;
    }

    .text-right {
      text-align: center !important;
    }

    /* Checkbox 영역 */

    .checkbox {
      display: none;
    }

    .checkbox_label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .checkbox {
      + {
        .checkbox_icon {
          background: url("../static/img/none-bk/none-bk.png") no-repeat;
          width: 16px;
          height: 16px;
          display: inline-block;
          padding: 0;
        }
      }
    }

    .checkbox {
      &:checked {
        + {
          .checkbox_icon {
            background: url('../static/img/checked/checked.png') no-repeat;
            width: 16px;
            height: 16px;
            display: inline-block;
            padding: 0;
          }
        }
      }
    }


    .checkbox_name {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-left: 4px;
    }
  }
}


//Career 모바일 반응형
@media screen and (max-width: $mp) {

  .career {
    background: #fff;
    flex: 1;

    figure {
      color: #fff;
      .box{
        p{
          margin-left: 0px;
        }
      }
    }

    .inner {
      padding: 0;
      margin: 0;
    }

    .career_content {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: #ffffff;
      padding: 0;
    }

    .content_wrapper {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      display: block;
      padding: 100px 40px;
      justify-content: space-between;
      font-family: Montserrat, sans-serif;
      box-sizing: border-box;
    }

    .contents {
      width: 100%;
      max-width: 960px;
      padding: 0;
      margin-left: 0px;
    }

    .content_title {
      width: auto;
      flex: 1;
      font-family: NotoSansKR, sans-serif;
      font-size: 24px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #010101;
    }

    .content_title_wrapper {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .content_title_date {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      color: #969da9;
    }

    .content_item {
      margin-top: 30px;
    }

    .item_title {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #010101;
      margin-bottom: 10px;
    }

    .item_description {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.89;
      letter-spacing: normal;
      color: #010101;
      .item_span {
        font-size: 14px;
      }
    }

    // 우리가 일하는 환경
    .environment {
      margin-top: 100px;
    }

    .environment_items {
      display: flex;
      flex-wrap: wrap;
    }

    .environment_item {
      width: 100%;
      height: 167px;
      display: inline-block;
      margin-right: 0px;
      padding: 41px 30px 40px;
      border: solid 1px #d4d4d4;
      font-size: 18px;
    }

    .environment_title {
      font-family: NotoSansKR, sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    .environment_description {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-top: 20px;
    }

    // 채용중인 직군
    .position {
      margin-top: 84px;
      div:nth-child(2) {
        margin-top: 40px;
      }
    }

    // 채용 프로세스
    .process {
      margin-top: 100px;
    }

    // 온리안 입사 지원
    .career_apply {
      width: 100%;
      height: 100%;
      background: #000000;
      padding: 60px 0px;
    }

    .apply {
      width: 100%;
      max-width: 960px;
      padding: 0;
      margin-right: 0px;
    }

    .apply .content_title {
      color: #ffffff;
    }

    .apply_item {
      width: 100%;
      display: inline-block;
      margin-top: 40px;
      padding-right: 40px;
    }

    .apply_item.full {
      width: 100%;
    }

    .apply_title {
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }

    .apply_require {
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #22cace;
      margin-left: 4px;
    }

    .apply_input {
      width: 100%;
      height: 54px;
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #969da9;
      outline: none;
      border: none;
      background: transparent;
      border-bottom: 3px solid #ffffff;
      -webkit-appearance: none;
      -webkit-border-radius: 0;
    }

    .apply_btn {
      padding: 16px 101.5px 15px 102.5px;
      border-radius: 30px;
      background-color: #ffffff;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      opacity: 0.3;
      justify-content: center;
      cursor: pointer;
    }

    .apply_file_wrapper {
      display: flex;
      justify-content: flex-start;
      height: 48px;
      align-items: center;
      margin-top: 20px;
      border: 3px solid #ffffff;
      padding-left: 21px;
    }

    .apply_input_label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background: transparent;
      overflow: hidden;
      cursor: pointer;
      font-family: NotoSansKR, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 48px;
      letter-spacing: normal;
      color: #969da9;
      position: relative;
      z-index: 1000;
    }

    .d-xs-none {
      display: none;
    }

    .apply_input_file {
      display: none;
    }

    .apply_input_btn {
      position: absolute;
      right: 20px;
      z-index: 900;
    }

    .text-right {
      text-align: center !important;
    }

    /* Checkbox 영역 */

    .checkbox {
      display: none;
    }

    .checkbox_label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .checkbox {
      + {
        .checkbox_icon {
          background: url("../static/img/none-bk/none-bk.png") no-repeat;
          width: 16px;
          height: 16px;
          display: inline-block;
          padding: 0;
        }
      }
    }

    .checkbox {
      &:checked {
        + {
          .checkbox_icon {
            background: url('../static/img/checked/checked.png') no-repeat;
            width: 16px;
            height: 16px;
            display: inline-block;
            padding: 0;
          }
        }
      }
    }


    .checkbox_name {
      font-family: NotoSansKR, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-left: 4px;
    }
  }
}


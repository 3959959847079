* {
  margin:0;
  padding:0;
  font-family: Montserrat, sans-serif;
}
body,html{
  margin:0;
  padding:0;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body{
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.modal-open {
  overflow: hidden;
}

.App{
  width:100%; min-height:100%; display: flex;
  flex-direction: column;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
}

ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
p{
  margin-top: 0;
  margin-bottom: 1rem;
}
img{
  vertical-align: middle;
  border-style: none;
}

legend,
caption {
  position: absolute;
  top: -99999em;
}

fieldset {
  border: none;
}

table {
  border-collapse: collapse;
}

.hidden{
  display: none;
}
main, section{
  min-height:100%;
  box-sizing:border-box;
  //padding-bottom: 90px;
}

.App  > section{
  min-height: 100vh;
  overflow-x: hidden;
  //padding-bottom:40px;
}

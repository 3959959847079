.contact {
  flex: 1;

  .header {
    padding: 0 !important;
  }

  .inner {
    margin: 0;
    padding: 0;
  }

  .contact_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


    .contact_header_wrapper {
      width: 100%;
      height: 100%;
      min-height: 840px;
      background: #000000;
      flex: 1;
      padding: 150px 80px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .contact_header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;


        .contact_header_title {
          width: 100%;
          font-size: 42px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          padding-left: 220px;
        }

        .contact_with_us {
          width: 100%;
          font-family: Montserrat;
          font-size: 42px;
          text-align: center;
          color: #000000;
          margin-top: 83px;;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        }

        .contact_contact_method {
          font-family: NotoSansKR, sans-serif;
          width: 100%;
          font-size: 36px;
          font-weight: 700;
          font-stretch: normal;
          font-style: normal;
          line-height: 52.13px;
          letter-spacing: normal;
          color: #ffffff;
          margin-top: 20px;
          text-align: center;
          max-width: 826px;
        }

        .contact_header_description {
          width: 100%;
          font-family: NotoSansKR, sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #969da9;
          margin-top: 30px;
          text-align: center;
          align-items: center;
        }

        .contact_header_type {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .contact_email {
            color: #ffffff;
            transform: rotate(-90deg);
            position: absolute;
            left: -85px;
            bottom: 170px;
            font-size: 16px;
          }


          .send_btn {
            font-family: Montserrat;
            width: 260px;
            height: 50px;
            color: #000000;
            background-color: #ffffff;
            border-radius: 25px;
            border-color: #000000;
            margin-top: 155px;
            font-size: 16px;
            line-height: 19.5px;
            font-weight: 800;
            margin-bottom: 100px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//Career 모바일 반응형
@media screen and (max-width: $tablet) {
  .contact {
    flex: 1;

    .header {
      padding: 0 !important;
    }

    .inner {
      margin: 0;
      padding: 0;
    }

    .contact_container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;


      .contact_header_wrapper {
        width: 100%;
        height: 100%;
        min-height: 642px;
        background: #000000;
        flex: 1;
        padding: 150px 100px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact_header {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;


          .contact_header_title {
            width: 100%;
            font-size: 42px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            padding-left: 0px;
          }

          .contact_with_us {
            width: 100%;
            font-family: Montserrat;
            font-size: 42px;
            text-align: center;
            color: #000000;
            margin-top: 83px;;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
          }

          .contact_contact_method {
            width: 100%;
            font-family: Montserrat;
            font-size: 36px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 52.13px;
            letter-spacing: normal;
            color: #ffffff;
            margin-top: 20px;
            text-align: center;
          }

          .contact_header_description {
            width: 100%;
            font-family: NotoSansKR, sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #969da9;
            margin-top: 30px;
            text-align: center;
            align-items: center;
          }

          .contact_email {
            color: #ffffff;
            transform: rotate(-90deg);
            text-align: left;
            position: absolute;
            left: -80px;
            bottom: 100px;
            font-size: 16px;
          }

          .contact_header_type {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .contact_email {
              color: #ffffff;
              transform: rotate(-90deg);
              position: absolute;
              left: -85px;
              bottom: 170px;
              font-size: 16px;
              display: none;
            }

            .send_btn {
              width: 260px;
              height: 50px;
              color: #000000;
              background-color: #ffffff;
              border-radius: 25px;
              border-color: #000000;
              margin-top: 155px;
              font-size: 16px;
              line-height: 19.5px;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $mp){
  .contact {
    .contact_container {
      .contact_header_wrapper {
        padding: 10px 40px 0px;

        .contact_header {
          .contact_header_title{
            font-size: 26px;
            margin-top: 100px;
          }
          .contact_with_us {
            margin-top: 86px;
            font-size: 30px;
          }

          .contact_contact_method {
            font-size: 18px;
            line-height: 24.64px;
          }
          .contact_header_description {
            font-size: 14px;
           }
          .contact_header_type {
            .contact_email {
              display: none;
            }
            .send_btn {
              margin-top: 110px;
              margin-bottom: 90px;
            }
          }
          }
        }
      }
    }
  }

.modal{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10001;

  .modal_overlay{
    width: 100vw;
    height: 100vh;
    display: flex;
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    z-index: 11000;


    .modal_content_wrapper{
        position: relative;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 854px;

      .close_btn{
        position: absolute;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: black;
        cursor:pointer;
        z-index: 9999999;

        &.portfolio_deleteBtn{
          z-index: 99999999;
        }
      }

      .modal_content{
        width: 100%;
        max-width: 1440px;
        height: 100%;
        max-height: 854px;
        position: relative;
        background: transparent;
        padding: 0;
        //overflow-y: scroll;
        z-index: 11000;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

  }
}


@media screen and (max-width : $mp){
  .modal{
    .modal_overlay{
      .close_btn{
        width: 40px;
        height: 40px;
        padding: 7px;

        >img{
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }
    }
  }
}



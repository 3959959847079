.portfolio{
  flex: 1;

  figure{
    height: 258px;
    background-color:#fff;

    .box{
      padding-top: 117px;

      h1{
        width: 100%;
        max-width: 1280px;
        margin-bottom: 70px;
        margin-top: 22px;
        text-align: left;
        font-size: 42px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2e323d;
      }
    }
  }

  > .inner {
    margin:  0 auto 100px;

    .portfolio_container{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;


      .tabMenu{
        display: flex;
        width: 100%;
        max-width: 1280px;
        text-align: left;
        //border-bottom: 1px solid #ededed;
        position: relative;

        li{
          display: inline-block;
          position: relative;
          margin-right: 44px;
          padding-left: 4px;
          padding-right: 4px;
          padding-bottom: 12px;
          font-size: 20px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #dcdcdc;
          cursor: pointer;

          .portfolio__count{
            position: absolute;
            top: -4px;
            right: -12px;
            font-size: 12px;
          }

          a{
            color: #dcdcdc;
          }

          &.active{
            .portfolio__count{
              color: #000000;
            }

            a{
              color: #000000;
              border-bottom: 4px solid #000000;
            }

          }

        }

        &::after{
          content:'';
          display:block;
          width:200%;
          position: absolute;
          height: 1px;
          background-color: #ededed;
          bottom: 0;
          right: -50%;
        }
      }

      .tabContent{
        width: 100%;
        max-width: 1280px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 80px 20px ;
        margin-bottom: 80px;
        transition: .3s;

        li{
          position: relative;
        }
      }
      .portfolio__btn {
        width: 260px;
        height: 50px;
        display: block;
        margin: 20px auto 0;
        padding: 16px 103px 15px 104px;
        background-color: #000000;
        font-size: 16px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        outline: none;
        border: none;
        border-radius: 25px;
        cursor: pointer;
      }

      &.on{
        opacity: 1;
      }
    }

  }

}

.modal{
  .modal_content_wrapper{
    width: 100%;
    height: 100%;
    position: relative;


    .scrollView{
      overflow-y: scroll;
      height: 100%;

      .portfolio_img{
        width: 100%;
        height: auto;

        object-fit: cover;
        display: block;
        opacity: 1;
        transition: 0.8s;
      }

    }

    .modal_btn{
      width: 46px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      opacity: 0.4;
      border-radius: 23px;
      line-height: 20px;
      background-color: #000000;
      cursor: pointer;
      z-index: 9;

      &.prev{
        left: 20px;
        bottom: 120px;
        display: flex;
        transform: rotate(180deg);
      }
      &.next{
        right: 20px;
        bottom: 120px;
        display: flex;

      }
    }
  }

}

@media (min-width: 769px) and (max-width: 1280px){
  .portfolio{
    figure{
      padding-left: 16px;
    }
    > .inner {
      //padding-left: 80px;

      .portfolio_container{
        gap: 40px;

        .tabMenu{
          li{

          }
        }

        .tabContent{
          justify-content: space-between;
          gap: 75px 20px;
          margin-bottom: 0;

          >li{
            width: 48%;
          }
        }
        .portfolio__btn{
          margin-top:-20px
        }
      }
    }
  }
}

@media screen and (max-width : $mp){
  .portfolio {
    figure{
      height: 188px;

      .box{
        h1{
          font-size: 26px;
          margin-bottom: 2px;
        }
      }
    }
    > .inner {
      padding:0;

      .portfolio_container {
        padding: 0px 14px;

        .tabMenu {
          margin-left: 23px;
          gap: 75px 20px;

          li {
            font-size: 16px;
            padding-bottom: 6px;
            margin-right: 25px;

            .portfolio__count {
              position: absolute;
              top: -4px;
              right: -12px;
              font-size: 12px;
            }
          }
        }

        .tabContent{
          margin-bottom: 32px;

          li{
            width: 100%;
          }
        }
      }

    }

    .modal {
      .modal_btn {
        width: 40px;
        height: 40px;
        padding: 7px;
      }
    }
  }
}



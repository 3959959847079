#visual{
  width: 100vw;
  height: 800px;
  position: relative;
  background: #000000;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 0;

  .home_wrapper{
    width: 100%;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    position: relative;

    .home_title{
      width: 80%;
      max-width: 879px;
      position: absolute;
      bottom: 25%;
      left: 16.5%;
      z-index: 1000;
    }

    .home_description{
      width: 412px;
      height: 40px;
      position: absolute;
      bottom: 40px;
      right: 320px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: right;
      color: #636363;
    }

    .home_back_logo{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center right 80px;
      background-size: contain;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: $mp) {
  #visual {
    height: 620px;
    padding: 0;

    .home_wrapper {
      width: 100%;
      height: 100%;

      .home_title{
        width: 76%;
        left: 10%;
        bottom: 45%;
      }
      .home_description {
        width: 279px;
        height: 31px;
        font-size: 10px;
        line-height: 1.53;
        right: 30px;
      }

      .home_back_logo {
        background-size: 75%;
        background-position: center right 20px;

        img {
          width: 76%;
          left: 10%;
          bottom: 45%;
        }
      }
    }
  }
}

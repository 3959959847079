.spinner_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1000;
}

.spinner_img{
    width: 100px;
    height: 100%;
}


#business {
  width: 100vw;
  height: 100%;
  background-color: #f9fafb;
  position: relative;
  padding: 0 40px 135px;
  z-index: 2000;
  overflow-x: hidden;

  .business-container {
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin: auto;

    .business-bg {
      font-size: 180px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #f4f4f4;
      position: absolute;
      top: 80px;
      left: 80px;
      white-space: pre;
      z-index: 2900;
    }

    .business-title {
      width: 100%;
      text-align: right;
      font-size: 42px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2e323d;
      padding-top: 130px;
      z-index: 3000;
      position: relative;
    }

    .business-portfolio {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 95px;

      .business-block {
        width: 100%;
        max-width: 280px;
        height: 252px;
        padding: 40px 30px;
        border: solid 1px rgba(0, 0, 0, 0.2);
        margin-right: 40px;

        .business-item-title {
          font-size: 20px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 28px;
        }

        .business-item-description {
          font-family: NotoSansKR, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          word-break: break-all;
        }

        .business-bar {
          width: 60px;
          height: 8px;
          background: #000000;
          margin-bottom: 30px;

          &.right {
            margin-left: auto;
          }
        }

        .business-service-title {
          font-size: 36px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }

        &.right {
          text-align: right;
          margin-right: 0;
          margin-left: 40px;
        }

        &.no-border {
          border: none;
          padding: 0;
          margin: 0 40px;
        }

        &.ict {
          order: 5;
        }
      }

      .business-btn {
        width: 145px;
        height: 46px;
        padding: 13px 20px 13px 26px;
        border-radius: 23px;
        background-color: #000000;
        margin-top: 60px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        outline: none;
        border: none;
        cursor: pointer;
        display: inline-flex;
        gap: 5px;

        &.mb_btn{
          display: none;
        }
      }
    }
  }
}


@media (min-width: 769px) and (max-width: 1280px){
  #business{
    .business-container {
      max-width: 640px;

      .business-portfolio {
        flex-flow: row wrap;

        .business-block {
          width: 280px;
          margin-bottom: 20px;

          .business-bar{
            &.right{
              margin-left: 0;
            }
          }

          &.ict {
            order: 1;
          }

          &.no-border {
            border: none;
            padding: 0;
            margin-left: 0;
            margin-right: 40px;
          }
          &.right {
            text-align: left;
            margin-right: 40px;
            margin-left: 0;
          }
        }
      }
    }
  }
}


@media screen and (max-width : $mp){
  #business {
    height: auto;
    padding-bottom: 100px;

    .business-container {
      height: auto;
      padding: 0;

      .business-bg {
        display: none;
      }
      .business-title {
        font-size: 26px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #e0e0e0;
        padding-top: 88px;
      }
      .business-portfolio {
        flex-direction: column;
        margin-top: 60px;

        .business-block {
          width: 100%;
          max-width: 100%;
          height: auto;
          margin-right: 0;
          margin-bottom: 30px;

          .business-bar {
            &.right{
              margin-left: 0;
            }
          }

          .business-service-title {
            font-size: 24px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }

          .business-item-title {
            font-size: 18px;
          }

          .business-item-description {
            font-size: 14px;
            line-height: 20px;
          }

          .business-bar {
            width: 32px;
            height: 5px;
            margin-bottom: 18px;

            &.right {
              margin-left: 0;
            }
          }

          &.no-border {
            border: none;
            padding: 0;
            margin: 0 0 30px 0;
          }

          &.right {
            text-align: left;
            margin-left: 0;
          }

          &.business-block.ict{
            order:1;
          }
        }

        .business-btn {
          display: none;
          margin-top: 10px;
          margin-left: auto;

          &.d-xs-block {
            display: inline-block !important;
          }

          &.mb_btn{
            display: inline-flex;
          }
        }
      }
    }
  }
}

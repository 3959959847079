.leaders {
  background: #fff;
  flex: 1;

  figure {
    color: #fff;
    .box {
      h1 {
        width: 100%;
      }
    }
  }
  .inner{
    padding: 0;
    margin: 0;
  }

  .leaders_container {
    width: 100%;
    height: 100%;
    //background: white;
  }

  .leaders_content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: white;
    padding: 0 40px;
    margin-top: 100px;
    margin-bottom: 472px;
  }
  .leaders_container {
    .leaders_wrapper {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      gap: 140px;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      .business_service_title {
        font-family: Montserrat, sans-serif;
        font-size: 36px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        color: #000000;
        text-align: right;
      }
      article {
        width: 100%;
        max-width: 1280px;
        display: flex;
        >img {
          width: 90%;
          max-width: 375px;
          height: 555px;
          object-fit: scale-down;
        }
        >div {
          .img-right {
            width: 90%;
            max-width: 375px;
            height: 555px;
            float: right;
          }
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .about_description {
            font-family: Noto Sans, sans-serif;
            font-size: 18px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: normal;
            text-align: center;
            color: #000000;
            margin-bottom: 50px;
            margin-left: 92px;
            margin-top: 50px;
          }

          .leaders_block {
            .business_btn {
              width: 157px;
              height: 46px;
              display: inline-block;
              padding: 13px 20px 13px 20px;
              border-radius: 23px;
              background-color: #000000;
              margin-top: 60px;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 19.5px;
              letter-spacing: normal;
              color: #ffffff;
              outline: none;
              border: none;
              cursor: pointer;
              margin-right: 0px;
            }
            text-align: right;
          }
          .top {
            margin-top: 83px;
            margin-left: 92px;
            margin-bottom: 52px;
            position: relative;
            div {
              position: absolute;
              &:first-child {
                left: 0;
              }
              &:nth-child(2) {
                right: 0;
              }
            }
          }
          title {
            margin-bottom: 10px;
          }
          .title {
            h2 {
              margin-left: 92px;
              display: inline;
              font-family: Montserrat, sans-serif;
              font-size: 57px;
              font-weight: 700;
              font-stretch: normal;
              font-style: normal;
              line-height: 70px;
              letter-spacing: normal;
              text-align: center;
            }
            span {
              font-family: Montserrat, sans-serif;
              font-size: 22px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 27px;
              letter-spacing: normal;
              text-align: center;
            }
          }
        }
        &:nth-child(2n) {
          flex-direction: row-reverse;
          div {
            .top {
              margin-top: 83px;
              margin-right: 92px;
              margin-bottom: 52px;
              position: relative;
            }
            .about_description {
              font-family: Montserrat, sans-serif;
              font-size: 18px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 22px;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
              margin-bottom: 50px;
              margin-left: 92px;
              margin-top: 50px;
              margin-right: 92px;
            }
          }
          >div {
            .leaders_block {
              .business_btn {
                width: 157px;
                height: 46px;
                display: inline-block;
                padding: 13px 20px 13px 20px;
                border-radius: 23px;
                background-color: #000000;
                margin-top: 60px;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 19.5px;
                letter-spacing: normal;
                color: #ffffff;
                outline: none;
                border: none;
                cursor: pointer;
                margin-right: 92px;
              }
              .right_arrow {
                transform: translate(-120px, -1px);
                content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
              }

            }
            .view_btn{
              width: 145px;
              height: 46px;
              display: inline-block;
              padding: 13px 20px 13px 25px;
              border-radius: 23px;
              background-color: #000000;
              margin-top: 60px;
              font-size: 13px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;
              outline: none;
              border: none;
              cursor: pointer;
              margin-right: 92px;
            }
          }
        }
      }
    }
    .header {
      width: 100%;
      max-width: 1280px;
      height: 100%;
      margin: 0 auto;
      padding-top: 180px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .header_title {
      font-size: 42px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-right: 40px;
      margin-left: 100px;
    }
    .header_description {
      width: 100%;
      max-width: 960px;
      font-family: NotoSansKR, sans-serif;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.2px;
      color: #ffffff;
    }
  }
  .leaders_block {
    width: 100%;
    margin-bottom: 20px;
  }
  .leaders_block.right {
    text-align: left;
    margin-right: 40px;
    margin-left: 0;
  }

  .leaders_block.ict {
    order: 1;
  }
  .business_bar {
    width: 60px;
    height: 8px;
    background: #000000;
    margin-bottom: 30px;
  }

  .business_bar.right {
    margin-left: 0;
  }
  .business_block.right {
    text-align: left;
    margin-right: 40px;
    margin-left: 0;
  }
  .business_block.no_border {
    border: none;
    padding: 0;
    margin-left: 0;
    margin-right: 40px;
  }

  .d-xs-block {
    display: inline-block !important;
  }

  .right_arrow {
    transform: translate(-30px, -1px);
    content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
  }

}

/* Leaders * Modal*/

.modal_leaders_container {
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: scroll;

  .modal_header_wrapper {
    justify-content: space-around;
    align-items: center;
    background-image: url("../static/img/career-back-img/career-back-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 286px;
    max-height: 186px;
    margin: 0;
    //padding: 0 40px;
    background-position-x: right;

    .modal_header {
      width: 100%;
      max-width: 1280px;
      max-height: 186px;
      height: 100%;
      margin: 0px;
      padding-top: 77px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .modal_header_title {
        font-size: 26px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-right: 40px;
        margin-left: 80px;
      }
    }

    .modal_leaders_content {
      width: 100%;
      height: auto;
      min-height: 0vh;
      background: white;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;

      .modal_leaders_wrapper {
        width: 100%;
        max-width: 1280px;
        margin: 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;

        .modal_article {
          width: 100%;
          max-width: 1280px;
          display: flex;
          //display: none;

          .modal_img {
            margin-top: 53px;
            width: 90%;
            max-width: 375px;
            height: 555px;
            object-fit: scale-down;
            float: right;
            margin-left: 80px;
            margin-bottom: 60px;
          }

          .leaders_modal_content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .modal_top{
              margin-top: 70px;
              margin-left: 92px;
              margin-bottom: 56px;
              position: relative;

              .modal_bar {
                width: 60px;
                height: 8px;
                background: #000000;
                margin-bottom: 30px;
                left: 0;
                position: absolute;
              }
              .modal_service_title {
                font-family: Montserrat, sans-serif;
                font-size: 36px;
                font-weight: 700;
                font-stretch: normal;
                font-style: normal;
                line-height: 30px;
                letter-spacing: normal;
                color: #000000;
                text-align: right;
              }

            }
            div {
              .modal_enName {
                margin-left: 92px;
                display: inline;
                font-family: Montserrat, sans-serif;
                font-size: 57px;
                font-weight: 700;
                font-stretch: normal;
                font-style: normal;
                line-height: 70px;
                letter-spacing: normal;
                text-align: center;
              }
              .modal_Name {
                font-family: Montserrat, sans-serif;
                font-size: 22px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 27px;
                letter-spacing: normal;
                text-align: center;
              }
            }

            .modal_about_description {
              font-family: Montserrat, sans-serif;
              font-size: 18px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 22px;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
              margin-bottom: 30px;
              margin-left: 92px;
              margin-top: 20px;
            }

            .modal_career_list{
              display: flex;

              .modal_icon_list{
                display: flex;
                flex-direction: row;
                margin-top: 75px;
                margin-left: 172px;
                flex-wrap: wrap;
                .modal_icons{
                  position: relative;
                  margin-right: 11px;
                  margin-bottom: 5px;
                  cursor: pointer;
                  &.non{
                    cursor: default;
                  }

                   div  {
                      display: inline-flex;

                      .icon_title {
                        display: none;
                        position: absolute;
                        min-width: 70px;
                        max-width: 102px;
                        padding: 8px;
                        left: -200%;
                        bottom: 120%;
                        transform: translateX(50%);
                        -webkit-border-radius: 8px;
                        -moz-border-radius: 8px;
                        border-radius: 8px;
                        background: #333;
                        color: #fff;
                        opacity: 0;
                        visibility: hidden;
                        font-size: 12px;
                        transition: .3s;

                      &:after{
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        margin-left: -10px;
                        border:8px solid rgba(51, 51, 51, 0);
                        border-bottom-color: #333;
                        pointer-events: none;
                        transform: rotate(180deg);
                        content: ' ';
                        }


                        &.msm{
                          left: -120%;
                        }
                      }

                     &:hover {
                       .icon_title {
                         opacity: 1;
                         visibility: visible;
                         display: inline-block;
                       }
                     }
                    }

                  &:first-child{
                    a{
                      .icon_title{
                        //left:-120%;
                      }
                    }
                  }
                }
              }
              .modal_career_span{
                flex-wrap: wrap;
                align-items: flex-start;
                margin-left: 92px;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
              }
              .modal_career_description{
                margin-left: 28px;
              }
            }
          }
        }
      }
    }
  }
}

.modal_leaders_container::-webkit-scrollbar {
  display: none;
}


.view_btn{
  width: 145px;
  height: 46px;
  display: inline-block;
  padding: 13px 20px 13px 25px;
  border-radius: 23px;
  background-color: #000000;
  margin-top: 60px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;

  .withe_arrow {
    margin-left: 5px;
    content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
  }
}
.d_block {
  display: inline-block !important
}


/* Leaders * 모바일*/
@media screen and (max-width: $mp + 225) {
  .leaders {
    background: #fff;
    flex: 1;

    figure {
      color: #fff;
      .box {
        h1 {
          width: 100%;
          text-align: center;
        }
      }
    }

    .leaders_container {
      width: 100%;
      height: 100%;
      background: white;
    }

    .leaders_content {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: white;
      padding: 0px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .leaders_container {
      .leaders_wrapper {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        gap: 30px;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        .business_service_title {
          font-family: Montserrat, sans-serif;
          font-size: 24px;
          font-weight: 700;
          font-style: normal;
          line-height: 18px;
          color: #000000;
          text-align: right;
        }
        article {
          width: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          >img {
            width: 90%;
            max-width: 375px;
            min-width: 375px;
            height: 555px;
            object-fit: scale-down;
          }
          >div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .img-right {
              width: 90%;
              max-width: 375px;
              height: 555px;
              object-fit: scale-down;
              float: right;
            }
            //display: flex;
            //flex-direction: column;
            //justify-content: flex-start;

            .about_description {
              font-family: Montserrat, sans-serif;
              font-size: 18px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 22px;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
              margin-bottom: 50px;
              margin-left: 40px;
              margin-right: 40px;
              margin-top: 30px;
            }

            .leaders_block {
              .business_btn {
                width: 157px;
                height: 46px;
                display: inline-block;
                padding: 13px 20px 13px 20px;
                border-radius: 23px;
                background-color: #000000;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 19.5px;
                letter-spacing: normal;
                color: #ffffff;
                outline: none;
                border: none;
                cursor: pointer;
                margin-top: 0px;
                margin-left: 178px;
                margin-right: 40px;
              }
              text-align: right;
            }
            .top {
              margin-top: 30px;
              margin-left: 40px;
              margin-right: 30px;
              margin-bottom: 43px;
              position: relative;
              div {
                position: absolute;
                &:first-child {
                  left: 0;
                }
                &:nth-child(2) {
                  right: 0;
                }
              }
            }
            title {
              margin-bottom: 10px;
            }
            .title {
              h2 {
                font-family: Montserrat, sans-serif;
                font-size: 26px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: 32px;
                letter-spacing: normal;
                text-align: center;
                margin-left: 40px;
                display: inline;
              }
              span {
                font-family: Montserrat, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
              }
            }
          }
          &:nth-child(2n) {
            flex-direction: column;
            div {
              .top {
                margin-top: 30px;
                margin-left: 40px;
                margin-right: 30px;
                margin-bottom: 43px;
                position: relative;
              }
              .about_description {
                font-family: Montserrat, sans-serif;
                font-size: 18px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 22px;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
                margin-bottom: 50px;
                margin-left: 40px;
                margin-right: 40px;
                margin-top: 30px;
              }
            }
            >div {
              .leaders_block {
                .business_btn {
                  width: 157px;
                  height: 46px;
                  display: inline-block;
                  padding: 13px 20px 13px 20px;
                  border-radius: 23px;
                  background-color: #000000;
                  font-size: 16px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 19.5px;
                  letter-spacing: normal;
                  color: #ffffff;
                  outline: none;
                  border: none;
                  cursor: pointer;
                  margin-top: 0px;
                  margin-left: 178px;
                  margin-right: 40px;
                }
                .right_arrow {
                  transform: translate(-50 px, -33px);
                  content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
                }
                .view_btn{
                  width: 145px;
                  height: 46px;
                  display: inline-block;
                  padding: 13px 20px 13px 25px;
                  border-radius: 23px;
                  background-color: #000000;
                  margin-top: 60px;
                  font-size: 13px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #ffffff;
                  outline: none;
                  border: none;
                  cursor: pointer;
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
      .header {
        width: 100%;
        max-width: 1280px;
        height: 100%;
        margin: 0 auto;
        padding-top: 180px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .header_title {
        font-size: 42px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-right: 40px;
        margin-left: 100px;
      }
      .header_description {
        width: 100%;
        max-width: 960px;
        font-family: NotoSansKR, sans-serif;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.2px;
        color: #ffffff;
      }
    }
    .leaders_block {
      width: 100%;
      margin-bottom: 20px;
      text-align: center !important;
    }
    .leaders_block.right {
      text-align: left;
      margin-right: 40px;
      margin-left: 0;
    }

    .leaders_block.ict {
      order: 1;
    }
    .business_bar {
      width: 32px;
      height: 5px;
      margin-bottom: 18px
    }

    .business_bar.right {
      margin-left: 0;
    }
    .business_block.right {
      text-align: left;
      margin-right: 40px;
      margin-left: 0;
    }
    .business_block.no_border {
      border: none;
      padding: 0;
      margin-left: 0;
      margin-right: 40px;
    }

    .leaders_img{
      content:url("../static/img/leaders/sample.png");
    }

    .d-xs-block {
      display: inline-block !important;
    }

    .right_arrow {
      transform: translate(-50 px, -33px);
      content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
    }

    .btn_div {
      display: inline-block !important;
    }
    .view_btn{
      width: 145px;
      height: 46px;
      display: inline-block;
      padding: 13px 20px 13px 25px;
      border-radius: 23px;
      background-color: #000000;
      margin-top: 60px;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
    }
    .d_block {
      display: inline-block !important
    }

    .withe_arrow {
      margin-left: 5px;
      content:url("../static/img/btn-arrow-wh/btn-arrow-wh.png");
    }
  }
}

/* Leaders * Modal 모바일 */

@media screen and (max-width: $mp + 225) {


  .modal_leaders_container {
    .modal_header_wrapper {
      .modal_header {
        .modal_header_title {
          font-size: 26px;
          margin-left: 40px;
          margin-right: 64px;
        }
      }
      .modal_leaders_content {
        .modal_leaders_wrapper {
          .modal_article {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .modal_img {
              margin: 0px;
              object-fit: scale-down;
              float: right;
            }
            .leaders_modal_content {
              .modal_top{
                margin: 31px 40px 5px;

                .modal_bar {
                  width: 32px;
                  height: 5px;
                }
                .modal_service_title {
                  font-size: 24px;
                }

              }
              div {
                .modal_enName {
                  font-size: 26px;
                  margin-left: 40px;
                  margin-top: 43px;
                }
                .modal_Name {
                  font-family: Noto Sans, sans-serif;
                  font-size: 14px;
                }
              }

              .modal_about_description {
                font-family: Noto Sans, sans-serif;
                font-size: 16px;
                margin-left: 40px;
              }
              .modal_career_list{
                flex-direction: column;
                .modal_icon_list{
                  margin-left: 40px;
                  margin-top: 40px;
                  .modal_icons{
                    &.non{
                    }
                    div  {

                      .icon_title {
                        &:after{
                        }
                        &.msm{
                        }
                      }

                      &:hover {
                        .icon_title {
                        }
                      }
                    }

                    &:first-child{
                      a{
                        .icon_title{
                        }
                      }
                    }
                  }
                }
                .modal_career_span{
                  font-family: Noto Sans, sans-serif;
                  font-size: 18px;
                  font-weight: 700;
                  margin-left: 40px;
                }
                .modal_career_description{
                  font-size: 14px;
                  margin-top: 20px;
                  margin-left: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}


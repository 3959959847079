header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 100px;
  top: 0;
  transition: all 0.5s;
  padding: 20px 80px;
  background: transparent;
  border: none;
  z-index: 10001;


    h1{
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-tap-highlight-color: transparent;

      .logo{
        color: #fff;
        font-size: 0;
        width: 120px;
        height: 28px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position-x: 0;
        background-position-y: -13px;

        &.black{
          background-position-y: -45px;
        }
      }
    }

    div {
      width: 54px;
      height: 25px;
      cursor: pointer;
      margin-bottom: 0;
      background-repeat: no-repeat;
      background-position-x: -119px;
      background-position-y: -15px;
      -webkit-tap-highlight-color: transparent;

      &.black{
        background-position-y: -44px;
      }
    }

}


@media (min-width: 769px) and (max-width: 1280px){
  header{
    padding: 20px 40px;
  }
}


@media screen and (max-width : $mp){
  header {
    height: 60px;
    padding: 0 30px;

    h1 {
      .logo {
        width: 90px;
        background-position-y: -68px;

        &.black {
          background-position-y: -93px;
        }
      }
    }
    div {
      background-position-x: -97px;
      background-position-y: -70px;

      &.black{
        background-position-y: -95px;
      }
    }
  }}

#menuWrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  z-index: 99999;
  padding: 30px 80px 30px 30%;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;


  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;
    padding: 1rem;
    margin-top: 55px;

    li{
      a {
        font-size: 56px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

      }

      &:first-child{
        margin-top: 111px;
      }
    }
  }

  .close_btn{
    position: fixed;
    right: 96px;
    top: 47px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    cursor:pointer;
    -webkit-tap-highlight-color: transparent;
  }
}


@media screen and (max-width : $mp){
  #menuWrapper{
    justify-content: flex-start;
    padding-left: 77px;

    ul{
      align-items: flex-start;
      gap: 28px;
      margin-top: 41px;

      li{
        a{
          font-size: 30px;
        }
      }
    }

    .close_btn{
      right: 37px;

      img{
        width: 36px;
        height: 36px;
      }
    }
  }
}


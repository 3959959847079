$logo-space : 40px;
$logo-size : 40px;
$logo-total-col : 6;
$logo-total-row : 4;


.portfolio-icon{
  background-image: url(../static/img/logo/serviceLogos.png);
  width: 40px;
  height: 40px;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 50%;

  @for $i from 0 through $logo-total-col {
    &.col#{$i}{
      background-position-x: $logo-space * $i * -1 ;
    }
  }
  @for $i from 0 through $logo-total-row {
    &.row#{$i}{
      background-position-y: $logo-space * $i * -1 ;
    }
  }
  //후다닥
  &.wdd{
    @extend .row0;
    @extend .col1;
  }
  //엄마의지도
  &.msm{
    @extend .row0;
    @extend .col2;
  }
  //마인드그라프
  &.mag{
    @extend .row0;
    @extend .col3;
  }
  //와프너
  &.wap{
    @extend .row0;
    @extend .col4;
  }
  //bac
  &.bac{
    @extend .row0;
    @extend .col5;
  }
  //랜선클린
   &.lcl{
      @extend .row1;
     @extend .col0;
  }
  //스타일링 게임
  &.stl{
    @extend .row1;
    @extend .col2;
  }
  //지역화폐
  &.ggc{
    @extend .row1;
    @extend .col1;
  }
  //랜선 하우스
  &.lhs{
    @extend .row1;
    @extend .col3;
  }
  //우리 클래스
  &.ucl{
    @extend .row1;
    @extend .col4;
  }
  //스타일 물어봐
  &.sml{
    @extend .row1;
    @extend .col5;
  }
 //바운스 트램플린
  &.vnc{
    @extend .row2;
    @extend .col0;
  }
  //어때냥
  &.dnc{
    @extend .row2;
    @extend .col1;
  }
  //깍개
  &.ggg{
    @extend .row2;
    @extend .col5;
  }
  //웰빙가든
  &.wbg{
    @extend .row3;
    @extend .col5;
  }
  //CIAT
  &.ciat{
    @extend .row2;
    @extend .col2;
  }
  //세움
  &.seum{
    @extend .row2;
    @extend .col4;
  }
  //아라소사이어티
  &.ara{
    @extend .row2;
    @extend .col3;
  }
 // 핸디즈
  &.hds{
    @extend .row3;
    @extend .col0;
  }
  // 디너의여왕
  &.dnq{
    @extend .row3;
    @extend .col1;
  }
  // MYLO
  &.mylo{
    @extend .row3;
    @extend .col2;
  }
  // 쿠차
  &.chc{
    @extend .row3;
    @extend .col3;
  }
  // 굿닥
  &.gd{
    @extend .row3;
    @extend .col4;
  }
  // LNG 벙커링
  &.lng{
    //아이콘 없음
  }

  // LNG 벙커링, FAS, ERWS
  &.fas{
    //아이콘 없음
  }
}

footer {
  position: relative;
  width: 100%;
  height: 90px;
  //margin-top:-80px;
  background: #000;

  p {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

  }
}


@media screen and (max-width: $mp) {
  footer {
    height: 63px;

    p{
      font-size: 10px;
    }
  }
}

#partners{
  width: 100vw;
  background: #000000;
  position: relative;
  padding: 100px 40px;
  z-index: 1;

  .partner-container {
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin: auto;

    .partner-bg {
      font-size: 180px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111111;
      position: absolute;
      top: 80px;
      right: 80px;
      z-index: 900;
    }

    .partner-title{
      font-size: 42px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding-top: 30px;
      margin-bottom: 50px;
      z-index: 1000;
      position: relative;
    }
    .partner-logos{
      width: 100%;
      max-width: 1000px;
      margin: auto;
      z-index: 1000;
      text-align: center;

      li{
        width: 24%;
        display: inline-block;

        img{
          width: 100%;
        }
      }
    }
  }
}



@media (min-width: 769px) and (max-width: 1280px){

  #partners{
    .partner-container {
      max-width: 640px;
    }
  }
}


@media screen and (max-width : $mp){
  #partners{
    .partner-container {
      //max-width: 640px;

      .partner-title{
        padding-top: 0;
        margin-bottom: 40px;
        font-size: 26px;
      }
      .partner-bg{
        display: none;
      }

      .partner-logos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        justify-items: center;

        li{
          text-align: center;
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width : 267px){
  #partners{
    .partner-container {
      .partner-logos{

        li{
          width:100%;
        }
      }
    }
  }
}

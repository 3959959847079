.content {

  figure{
    width: 100%;
    height: 380px;
    background-color: #000;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0 40px;

    .box {
      width: 100%;
      max-width: 1280px;
      height: 100%;
      margin: 0 auto;
      padding-top: 180px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      h1 {
        font-size: 42px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-right: 40px;
      }
      p{
        width: 100%;
        max-width: 960px;
        font-family: NotoSansKR, sans-serif;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.2px;
        color: #ffffff;
      }
    }
  }
  > .inner {
    width: 100%;
    margin: 100px auto;
    position: relative;
    padding: 0 1rem;

  }
}

@media (min-width: 769px) and (max-width: 1280px){

}

@media screen and (max-width: $mp){
  .content {

    figure{

      .box {
        padding: 120px 0px 40px;
        flex-direction: column;
        justify-content: space-around;

        h1 {
          font-size: 26px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
        }
        p{
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.35;
          letter-spacing: 1px;
        }
      }
    }
    > .inner {


      h1 {

      }
    }
  }
}

.portfolio__content {
    width: 400px;
    padding-top: calc(100%  /199 * 129);
    position: relative;
    border: solid 1px #f4f4f4;
    overflow: hidden;
}

.portfolio__img {
    width: 100%;
    z-index: 900;
    opacity: 0;
}

.on{
    transition: .6s;
    opacity: 1;
}

.imageWrapper{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
}

.placeholderImg{
    background: linear-gradient(-45deg, #a0a0a0, #e1e1e1);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
    width: 100%;
    height: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.viewMore {
    cursor: pointer;
}

.viewMore:hover::after{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    content: 'VIEW MORE';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}


.portfolio__name {
    position: absolute;
    font-family: NotoSansKR, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-top: 15px;
    margin-left: 15px;
}

.portfolio__tags {
    position: absolute;
    bottom: -8px;
    left: 15px;
    z-index: 1200;
    display: flex;
    gap: 3px;
}

.portfolio__tag {
    width: auto;
    height: 20px;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 1px;
    background-color: #000000;
    font-size: 13px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #ffffff;
}

.portfolio__gradient {
    width: 100%;
    height:50%;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0.4;
    background-image: linear-gradient(to bottom, transparent, black 117%);
    z-index: 1000;
}


@media (min-width: 769px) and (max-width: 1280px){
    .portfolio__content{
        width: 100%;
    }
}


@media screen and (max-width : 769px){
    .portfolio__content {
        width: 100%;
        max-width: 100%;
        height: 200px;
        padding-top: 0;
    }

    .imageWrapper{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .portfolio__img {
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
        padding-top: 0;
    }

    .portfolio__name {
        font-size: 16px;
    }
}


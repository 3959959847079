#about{
 width: 100vw;
 height: 100%;
 max-height: 1100px;
 padding: 220px 40px 130px;
 position: relative;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 .about-bg {
  position: absolute;
  top: 45px;
  right: 45px;
  font-size: 180px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f4f4f4;
  z-index: 1;
 }

 .about-container{
  width: 100%;
  max-width: 1220px;

  div{
   display: flex;

   .about-block {
    width: auto;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .about-title {
     font-size: 42px;
     font-weight: 800;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #2e323d;
     margin-bottom: 56px;
    }
    .about-description {
     font-family: NotoSansKR, sans-serif;
     font-size: 18px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 26px;
     letter-spacing: normal;
     color: #000000;
     margin-bottom: 30px;
    }
    .about-famous{
     font-size: 14px;
     font-weight: normal;
     font-stretch: normal;
     font-style: italic;
     line-height: 1.93;
     letter-spacing: normal;
     color: #838ca0;
     margin-top: 55px;
     margin-bottom: 1rem;
    }
    .about-author {
     width: 100%;
     text-align: end;
     font-size: 14px;
     font-weight: normal;
     font-stretch: normal;
     font-style: italic;
     line-height: 1.93;
     letter-spacing: normal;
     color: #838ca0;
    }
   }

   &:nth-child(2){
    margin-left: 35px;
   }
  }
  .about-list{
   width: 100%;
   max-width: 1154px;
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-top: 60px;

   .about-item{
    width: auto;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .about-item-no {
     font-size: 22px;
     font-weight: 800;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #000000;
     z-index: 1000;
    }

    .about-item-title {
     font-size: 22px;
     font-weight: 800;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     color: #000000;
     margin-top: 20px;
     margin-bottom: 12px;
     z-index: 1000;
    }
    .about-item-description {
     font-family: NotoSansKR, sans-serif;
     font-size: 18px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 26px;
     letter-spacing: normal;
     color: #000000;
     z-index: 1000;
    }
   }
  }

 }
}

@media (min-width: 769px) and (max-width: 1280px){
 #about {
  max-height: 100%;

  .about-container {
   width: 640px;

   div{
    flex-direction: column;
    align-items: center;

    .about-block {
     max-width: 640px;
    }
   }

   .about-list {
    justify-content: flex-start;
    flex-wrap: wrap;

    .about-item {
     width: 280px;
     max-width: 280px;
     height: 252px;
     margin-right: 40px;
    }
   }
  }
 }
}

@media screen and (min-width: 1280px){
 #about{
  .about-container{
   div{
    .about-block:last-child {
     margin-left: 50px;
    }
    .about-famous{
     width:468px;
    }
   }
  }
 }
}

@media screen and (max-width : $mp){
 #about {
  width: 100%;
  height: auto;
  max-height: 100%;
  padding: 100px 40px 40px;

  .about-bg {
   display: none;
  }

  .about-container {
   width: 100%;
   height: auto;

   div{
    flex-direction: column;
    align-items: center;

    .about-block{
     .about-title {
      font-size: 26px;
      margin-bottom: 40px;
     }

     .about-description {
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 18px;
     }

     .about-img {
      height: 170px;
      margin-top: 70px;
      margin-left: 0;
     }
     .about-famous {
      font-size: 12px;
      text-align: center;
     }
     .about-author {
      font-size: 12px;
      text-align: center;
      line-height: 2.25;
     }
    }
    &:nth-child(2){
     margin-left: 0;
    }
   }

   .about-list {
    width: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .about-item {
     width: 100%;
     max-width: 100%;
     align-items: center;
     margin-right: 0;
     margin-bottom: 60px;

     .about-item-no {
      font-size: 18px;
     }

     .about-item-title {
      font-size: 18px;
      margin-top: 16px;
      margin-bottom: 8px;
     }

     .about-item-description {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
     }
    }
   }
  }

 }
}

#location{
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  padding: 130px 30px 120px;

  .location__container {
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;

    .location-bg {
      font-size: 180px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #f4f4f4;
      position: absolute;
      top: 56px;
      left: 128px;
      white-space: pre;
      z-index: 2900;
    }

    .location__title {
      font-size: 42px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #2e323d;
      position: relative;
      z-index: 2901;
    }

    .location__maps {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 88px;

      .map{
        width: 100%;
        max-width: 640px;
        height: 380px;
        flex: 1;
        margin-right: 80px;
        //border-radius: 30px;
        box-shadow: 0 20px 40px 0 rgb(0 0 0 / 10%);
        background-color: #d1d1d1;
        object-fit: cover;
      }

      .map__description {
        width: 100%;
        max-width: 440px;
        height: 300px;
        flex: 1;
        padding: 0 30px;

        .bar {
          width: 60px;
          height: 8px;
          margin-bottom: 33px;
          background-color: #000;
        }

        .map__title {
          font-weight: 800;
          font-size: 36px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-bottom: 49px;

          .map__branch {
            -webkit-text-stroke: 1.5px #000000;
            font-size: 26px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0);
          }
        }

        .map__address {
          margin-bottom: 40px;

          address{
            font-style: normal;
            font-family: NotoSansKR, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }
        }

        .map__text {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          margin-right: 8px;
          margin-bottom:40px;

          .map__text--small {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
          }

          h4{
            font-family: 'Montserrat';
            margin-bottom: 2px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
          }

          .email,
           .tel{
            display: inline-block;
            cursor: pointer;
          }
        }
        .map__btn-wrapper{
          text-align:right;

          .map__btn {
            width: 139px;
            height: 46px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            margin: 40px 0 0;
            padding: 13px 19px 13px 24px;
            border-radius: 23px;
            background-color: #000000;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            outline: none;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}


@media (min-width: 769px) and (max-width: 1280px){
  #location{
    padding:  88px 30px 53px;

    .location__container{
      .location-bg{
        display: none;
      }
      .location{
        max-width: 640px;
        margin: 0 auto;

        .location__maps {
          height: auto;
          flex-direction: column;

          .map {
            flex: none;
            height: auto;
          }

          .map__description {
            max-width: 100%;
            height: 300px;
            padding: 30px 0;

            >div:nth-of-type(5){
              margin-bottom: 0;
            }

            .bar {
              width: 32px;
              margin-bottom: 18px;
            }
            .map__title{
              font-size: 24px;
              margin-bottom: 30px;
            }
            .map__address{
              margin-bottom: 25px;
              address{
                font-size: 14px;
              }
            }
            .map__text{
              margin-bottom: 25px;

              .map__text--small{
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
              }
            }

            .btn-wrapper{
              .map__btn {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}



@media screen and (max-width : $mp){
  #location{
    padding:  88px 30px 53px;

    .location__container {
      .location-bg{
        display: none;
      }
      .location{
        width: 100%;
        padding: 0;

        .location__title {
          font-size: 26px;
        }

        .location__maps {
          width: 100%;
          height: auto;
          display: block;
          margin-top: 40px;

          .map {
            flex: none;
            height: auto;
          }

          .map__description {
            max-width: 100%;
            height: auto;
            padding: 40px 10px 30px;

            >div:nth-of-type(5){
              margin-bottom: 0;
            }

            .map__title {
              font-size: 18px;
            }

            .bar {
              width: 32px;
              height: 5px;
              margin-bottom: 18px;
            }
            .map__title{
              font-size: 24px;
              margin-bottom: 30px;
            }
            .map__address{
              margin-bottom: 25px;
              address{
                font-size: 14px;
              }
            }
            .map__text{
              margin-bottom: 25px;

              .map__text--small{
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
              }
            }

          }
        }
      }
    }
  }
}
